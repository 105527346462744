button{
    // button reset
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    outline: none!important;
    z-index: 2;
   
}
.btn{
    display: inline-block;
    height: 51px;
    border-radius: 11px;
    font-weight: 400;
    line-height: 48px;
    padding: 0 28px;
    border: 1px solid transparent;
    background-color: transparent;
    color: inherit;
    transition: all 300ms ease;
    &:hover{
        
        // border-color: @White40;
        // background-color: @White80;
        // color: @CapeCod;
    }
    &:focus,
    &:active{
        // border-color: @White40;
        // background-color: @Concrete;
        // color: @CapeCod;
    }
    // colors
    &_white{
    }
    // states
    // a&{
    //     text-decoration: none;
    // }
    &_icon{
    }
    // sizes
    &_md{
    }
    &_sm{
    }
    &_xs{
    }
}

.button{
    position: relative;
    min-width: 20rem;
    padding: 1.7rem 1.7rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
    text-transform: uppercase;
    background-color: $main;
    color: #fff;
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
    border:none;
    transition: background-color 0.3s ease;
    &:hover{
        color: #fff;
        background-color: #5a6268;
        border-color: #545b62;
    }
    &__gray{
        background-color: #E7E7E7;
        color: #333;
        &:hover{
            color: #333;
            background-color: lighten(#E7E7E7, 3);
        }
    }
}
.banner__top-form .button{
    width: 100%;
}