.c-service {
	position: relative;
	z-index: 1;

	&__wrap{
		display: flex;
		justify-content: space-between;
		padding: 3.5rem 0;
		flex-wrap: wrap;
		@media (max-width: $sw-xx-max) {
			padding: 1.5rem 0;
		}
	}

	&__item{
		width: 48%;
		@media (max-width: $sw-xs-max) {
			width: 100%;
			margin-top: 2rem;
		}
	}

	&__title{
		width: 100%;
		color: #333;
		font-size: 1.8rem;
		line-height: 2.1rem;
	}
}

.service-item{
	border: 0.5px solid #C1C1C1;
	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(178,178,178,0.5);
	overflow: hidden;
	margin: 3rem 0;
	@media (max-width: $sw-xs-max) {
		margin: 1rem 0;
	}
	&__img{
		height: 15rem;
		position: relative;
		overflow: hidden;
		.h-object-fit {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
		}
		&:before {
			content: ' ';
			visibility: hidden;
			display: block;
		}
		img{
			transition: all 0.5s ease;
		}
		.button{
			position: absolute;
			top: 50%;
			left: 50%;
			text-align: center;
			transform: translate(-50%, -50%);
		}
	}
	&__text{
		padding: 2rem;
		color: #333;
		@media (max-width: $sw-xx-max) {
			padding: 1rem;
		}
	}
	&__title{
		font-size: 1.5rem;
		display: block;
		margin-bottom: 5px;
	}
	&__descr{
		color: #646363;
		font-size: 1.4rem;
		line-height: 1.7rem;
		margin-bottom: 2rem;
	}
	&__link{
		font-size: 1.2rem;
		font-weight: bold;
		color: $main;
		transition: color 0.5s ease;
		&:hover{
			color: $accent;
		}
	}
	&:hover{
		.service-item__img{
			img{
				transform: scale(1.05);
			}
		}
	}
}