.l-wrapper{
	position: relative;
	min-height: 100vh;
	overflow: hidden;
	flex-direction: column;
	display: flex;
	main {
		flex-grow: 1;
	};
}

.col-extra-small {
	@media ( max-width: $sw-xx-max) {
		max-width: 100%;
		flex: 0 0 100%;
	}
}


a, p, span, button, input, time, h1, h2, h3, h4, h5, h6 {
	font-family: "Helvetica";
}

ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.main-banner-wrapper {
	padding-top: 110px;
	position: relative;
	// &:after{
 //    position: absolute;
 //    content: ' ';
 //    top: 0;
 //    bottom: 0;
 //    right: 0;
 //    left: 0;
 //    width: 100%;
 //    height: 100%;
 //    background: linear-gradient(280.23deg, rgba(30,123,183,0.2) 0%, rgba(13,69,129,0.2) 100%);
 //    z-index: 1;
 //    -webkit-filter: blur(2px);
 //      -moz-filter: blur(2px);
 //      -o-filter: blur(2px);
 //      -ms-filter: blur(2px);
 //      filter: blur(2px);
 //  }

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	@media(max-width: $sw-xx-max) {
		//padding-top: 70px;
		padding-top: 0;
		background-image: none !important;
		background-color: white;
	}
}

.mobile-noPadding {
	@media(max-width: $sw-xx-max) {
		padding-left: 0;
		padding-right: 0;
	}
}

.main-banner-wrapper_inner {
	position: relative;
	z-index: 2;
}

.main-banner-wrapper_blur {
	background-size: cover;
	background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
  @media(max-width: $sw-xx-max) {
		//padding-top: 70px;
		padding-top: 0;
		background-image: none !important;
		background-color: white;
	}
	&:before{
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(280.23deg, rgba(30,123,183,0.2) 0%, rgba(13,69,129,0.2) 100%);
      @media(max-width: $sw-xx-max) {
		//padding-top: 70px;
		display: none;
	}
  }
}