.c-breadcrumbs {
	position: relative;
	padding: 8px 25px;
	background-color: $gray-bg;
	@media (max-width: $sw-xx-max) {
		padding: 8px;
	}
	ul{
		display: flex;
		justify-content: flex-end;
		list-style-type: none;
		li{
			padding-right: 20px;
			position: relative;
			&:not(:last-child){
				&:after{
					position: absolute;
					content: '/';
					right: 8px;
					top: 0;
				}
			}
			a{
				text-decoration: none;
				color: #333;
				font-size: 1.5rem;
				line-height: 1.8rem;
				@media (max-width: $sw-xx-max){
					font-size: 1.3rem;
				}
			}
		}
	}
}