.banner {
  &__top {
    padding-top: 40px;
    position: relative;
    z-index: 1;
    @media(max-width: $sw-xs-max) {
      padding-top: 20px;
    }
    @media(max-width: $sw-xx-max) {
      padding-top: 0
    }
    &-ttl {
      position: relative;
      z-index: 1;
      @media(max-width: $sw-xs-max) {
        margin-bottom: 65px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      @media(max-width: $sw-xx-max) {
        margin-bottom: 25px;
      }
      h1 {
        color: #FFFFFF;
        font-size: 74px;
        letter-spacing: -0.8px;
        line-height: 1.1em;
        text-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
        @media(max-width: $sw-sm-max) {
          font-size: 58px;
        }
        @media(max-width: $sw-xs-max) {
          font-size: 40px;
          width: 100%;
          text-align: center;
        }
        @media(max-width: $sw-xx-max) {
          font-size: 28px;
          text-align: left;
        }
      }
      p {
        color: #FFFFFF;
        font-size: 30px;
        line-height: 1.2em;
        margin-bottom: 20px;
        @media(max-width: $sw-sm-max) {
          font-size: 24px;
        }
        @media(max-width: $sw-xs-max) {
          font-size: 18px;
          width: 100%;
          text-align: center;
        }
        @media(max-width: $sw-xx-max) {
          text-align: left;
        }
      }
      button {
        @media(max-width: $sw-xx-max) {
          margin-top: -25px;
        }
      }
      &-txt {
        position: relative;
        @media(max-width: $sw-xx-max) {
          padding: 25px 20px 45px;
        }


        &--content {
          position: relative;
          z-index: 2;
        }

        &--blur {
          background-size: cover;
          background-repeat: no-repeat;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          -webkit-filter: blur(1px);
          -moz-filter: blur(1px);
           -o-filter: blur(1px);
          -ms-filter: blur(1px);
           filter: blur(1px);

          @media(min-width: 480px) {
            background-image: none !important;
          }
          @media(max-width: 480px) {
            &:before{
              position: absolute;
              content: '';
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(280.23deg, rgba(30,123,183,0.2) 0%, rgba(13,69,129,0.2) 100%);
            }
          }
        }
      }
    }
    &-form {
      border-radius: 1px;
      background-color: #E7E7E7;
      padding: 40px 15px;
      margin: 0 0 0 -10px;
      @media(max-width: $sw-sm-max) {
        margin: 0 0 0 10px;
      }
      @media(max-width: $sw-xs-max) {
        width: 65%;
        margin: 0 auto;
      }
      @media(max-width: $sw-xx-max) {
        width: 100%;
        padding: 30px 10px;

        display: none;
      }
      h2 {
        color: #333333;
        font-size: 16px;
        line-height: 1.2em;
        text-align: center;
        margin-bottom: 10px;
      }
      p {
        color: #9C9C9C;
        font-size: 14px;
        line-height: 1.3em;
            white-space: pre-line;
        text-align: center;
      }
      form {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        button {
          margin-top: 35px;
          @media(max-width: $sw-xx-max) {
            margin-top: 15px;
          }
        }
      }
      .decor-field-group {
        width: 350px;
        @media (max-width: 1099px){
          width: 100%;
        }
      }
    }
  }
}

.decor-field {
  width: 100%;
  padding: 0 11px;
  height: 30px;
  transition: all .3s ease;
  line-height: 30px;

  border: 1px solid #D8D8D8;
  border-radius: 3px;
  background-color: #FFFFFF;
  &:focus {
    border: 1px solid #039BEF;
    outline: none;
    box-shadow: none;
  }
}

article {
  border-radius: 1px;
  background-color: #FFFFFF;
  padding: 24px 76px;
  @media(max-width: $sw-xs-max) {
    padding: 30px 35px;
  }
  @media(max-width: $sw-xx-max) {
    box-shadow: 0 0 5px #333;
    padding: 15px 10px;
  }
  h6 {
    color: #333333;
    font-size: 17px;
    margin-bottom: 15px;
    line-height: 1.5em;
    @media(max-width: $sw-xx-max) {
      margin-bottom: 8px;
    }
  }
  p {
    color: #333333;
    font-size: 15px;
    line-height: 1.5em;
    margin-bottom: 0;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  section {
    &:not(:last-child) {
      margin-bottom: 30px;
      @media(max-width: $sw-xx-max) {
        margin-bottom: 15px;
      }
    }
  }
}

.c-article {
  width: 100%;
  margin-top: 66px;
  position: relative;
  z-index: 1;

  @media(max-width: $sw-xx-max) {
    order: 2;
    margin-top: 15px;
  }
}

.banner-in-content {
  height: 19.5rem;
  position: relative;
  overflow: hidden;
  &:after{
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(280.23deg, rgba(30,123,183,0.2) 0%, rgba(13,69,129,0.2) 100%);
  }
  .h-object-fit {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    img{
      object-position: 0px -222px;
       -webkit-filter: blur(2px);
      -moz-filter: blur(2px);
      -o-filter: blur(2px);
      -ms-filter: blur(2px);
      filter: blur(2px);
      @media(max-width: $sw-xs-max) {
        object-position: 0px -122px;
      }
      @media(max-width: $sw-xs-max) {
        object-position: 0px 0px;
      }
    }
  }
  &:before {
    content: ' ';
    visibility: hidden;
    display: block;
  }
}