.c-footer{
	color: #563d7c;
	position: relative;
	background-color: $gray-bg;
	padding: 3.5rem 0 2.5rem 0;

  &__wrap{
  	display: flex;
  	justify-content: space-between;
  	flex-wrap: wrap;
  }

  &__item{
  	width: 23%;
  	padding: 0 1.5rem;
  	@media (max-width: $sw-sm-max) {
  		width: 50%;
  	}
  	@media (max-width: $sw-xs-max) {
  		width: 100%;
  		padding: 0;
  	}
  }
}

.footer-map{
	width: 31%;
	@media (max-width: $sw-sm-max) {
		width: 50%;
	}
	@media (max-width: $sw-xs-max) {
		width: 100%;
	}
}

.footer-item{
	font-size: 1.5rem;
	color: #646363;
	@media (max-width: $sw-xs-max) {
		&:nth-child(3){
			display: none;
		}
	}
	&__title{
		text-transform: uppercase;
		margin-bottom: 2rem;
		display: block;
		@media (max-width: $sw-xs-max) {
			margin-bottom: 1rem;
			color: #111;
			text-transform: none;
		}
	}
	&__text{
		margin-bottom: 2rem;
	}
	a{
		color: $main;
	}
	a.phone{
		color: #646363;
		&:hover{
			color: #646363;
		}
	}
}

#map{
	width: 30%;
	height: 200px;
	@media (max-width: $sw-sm-max) {
		width: 50%;
	}
	@media (max-width: $sw-xs-max) {
		width: 100%;
		order: 4;
	}
	@media (max-width: $sw-xx-max) {
		width: 100%;
	}
}

.footer-map{
	@media (max-width: $sw-xs-max) {
		width: 100%;
		order: 4;
		img{
			width: 50%;
			height: auto;
			object-fit: cover;
			display: block;
			margin: 0 auto;
		}
	}
	@media (max-width: $sw-xx-max) {
		img{
			width: 100%;
		}
	}
}