.c-marklastyling {
	position: relative;
	padding: 4.2rem 0;
	color: #333;
	@media (max-width: $sw-xs-max){
		padding: 3.5rem 0;
	}
	@media (max-width: $sw-xx-max){
		padding: 3rem 0;
	}


	[class*=col], [class*= col] {
		margin: 0 auto;
	}

	&__title{
		text-align: left;
		font-size: 1.8rem;
		line-height: 2.2rem;
		margin-bottom: 4rem;
		@media (max-width: $sw-xx-max){
			margin-bottom: 2rem;
		}
		&--left{
			text-align: left;
		}
	}

	&__text{
		font-size: 1.5rem;
		line-height: 2.2rem;
		margin-bottom: 4rem;
	}

	&__buttons{
		text-align: center;
	}

	&__form {
		form {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		h6 {
			text-align: center;
			font-weight: 700;
			margin-bottom: 80px;
			@media (max-width: $sw-xs-max){
				margin-bottom: 50px;
			}
			@media (max-width: $sw-xx-max){
				margin-bottom: 30px;
			}
		}
		.decor-field-group {
			width: 100%;
			margin-bottom: 25px;
		}
		.decor-field {
			height: 45px;
			padding-left: 20px;
		}
		button {
			margin-top: 15px;
			min-width: 28rem;
			@media (max-width: $sw-xx-max){
				min-width: 20rem;
			}
		}
	}
}



.c-marklastyling_formContainer {
	h3 {
		text-align: center;
		font-weight: 700;
		margin-bottom: 30px;
		font-size: 1.8rem;
		@media (max-width: $sw-xs-max){
			margin-bottom: 20px;
		}
		@media (max-width: $sw-xx-max){
			margin-bottom: 15px;
		}
	}
	.banner__top-form {
		max-width: 40rem;
		margin: 0 auto;
		background-color: #E7E7E7;
		@media(max-width: $sw-xx-max) {
			display: block;
		}
	}
}