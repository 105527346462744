// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Table of Contents
//
// Custom Color system
$scorpion: #585858;
// Color system
// Options
// Spacing
// Body
  //
  // Settings for the `<body>` element.

$body-bg:       $white;
$body-color:    $scorpion;
$main: #039BEF;
$accent: #FF0055;
$gray-bg: #E7E7E7;
$dark-bg: #1F2531;

// Links
  //
  // Style anchor elements.

  $link-decoration:       underline;
  $link-hover-decoration: none;

// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$headings-font-family: "Roboto";

$font-size-base: 1.4rem; // Assumes the browser default, typically `14px`

$line-height-base: 2.14285714;

$h1-font-size: 3.6rem;
$h2-font-size: 2.6rem;
$h3-font-size: 2.4rem;
$h4-font-size: 2rem;
$h5-font-size: 1.8rem;
$h6-font-size: 1.6rem;

// Components
// Tables
// Buttons
// Forms
// Dropdowns
// Z-index master list
// Navs
// Navbar
// Pagination
// Jumbotron
// Form states and alerts
// Cards
// Tooltips
// Popovers
// Badges
// Modals
// Alerts
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel
// Close
// Code

// Colors

//Red
$red-orange: #ff2c34;
$concrete: #f2f2f2;

// Components
$gen-lite: $red-orange;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$sw-xx-min: 0;
$sw-xs-min: 0;
$sw-sm-min: 800px;
$sw-md-min: 1100px;

$sw-xx-max: 479px;
$sw-xs-max: $sw-sm-min - 1px;
$sw-sm-max: $sw-md-min - 1px;

$grid-breakpoints: (
        xs: $sw-xs-min,
        sm: $sw-sm-min,
        md: $sw-md-min,
);
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 79rem,
        md: 101rem,
);
@include _assert-ascending($container-max-widths, "$container-max-widths");



// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$body-bg:       white;
$body-color:    black;

// Links
$link-decoration:       none;
$link-hover-decoration: none;


// Font, line-height, and color for body text, headings, and more.

$headings-font-family: "Circe";

$font-size-base: 1.6rem; // Assumes the browser default, typically `16px`
$font-size-xl:   1.6rem;
$font-size-lg:   1.6rem;
$font-size-md:   1.6rem;
$font-size-sm:   1.6rem;
$font-size-xs:   1.6rem;

$line-height-base: 1.2;
// $font-weight-normal: normal !default;
// $font-weight-bold: bold !default;

// $font-weight-base: $font-weight-normal !default;
// $line-height-base: 1.5 !default;

$h1-font-size: 3rem;
$h2-font-size: 2.8rem;
$h3-font-size: 2.5rem;
$h4-font-size: 2.2rem;
$h5-font-size: 2rem;
$h6-font-size: 1.7rem;


// Fonts
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Circe', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-serif:      'Circe', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, serif;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:       $font-family-sans-serif;

/* Forms */
$input-border-color: #d2d2d2;
$input-border-radius: 0;
$form-group-margin-bottom: 1.5rem;
//$input-btn-padding-y:0.5rem;
$input-btn-padding-x:1rem;


// Modals

// Padding applied to the modal body
$modal-inner-padding:         15px !default;

$modal-dialog-margin:         10px !default;
$modal-dialog-margin-y-sm-up: 30px !default;

$modal-title-line-height:     $line-height-base !default;

$modal-content-bg:               transparent;
$modal-content-border-color:     rgba($black,.2) !default;
$modal-content-border-width:     0;
$modal-content-box-shadow-xs:    none;
$modal-content-box-shadow-sm-up: none;

$modal-backdrop-bg:           transparent;
$modal-backdrop-opacity:      1;
$modal-header-border-color:   $gray-200 !default;
$modal-footer-border-color:   $modal-header-border-color !default;
$modal-header-border-width:   0;
$modal-footer-border-width:   $modal-header-border-width !default;
$modal-header-padding:        0;

$modal-lg:                    none;//830px
$modal-md:                    none;//830px
$modal-sm:                    none;//830px

$modal-transition:            transform .3s ease-out !default;






$grid-columns:               24;
$grid-gutter-width:     3rem;
$grid-gutter-widths: (
        xs: $grid-gutter-width,
        sm: $grid-gutter-width,
        md: $grid-gutter-width,
        lg: $grid-gutter-width
);

























