@import "_includes/mixins/index";
@import "_includes/libs/index";
@import "_includes/helpers/index";
@import "_includes/separate/index";
@import "_includes/components/index";

input[type=text], input[type=tel], input[type=email], select, textarea {
width: 100%;
padding: 12px;
border: 1px solid #ccc;
border-radius: 4px;
resize: vertical;
}

label {
padding: 18px 0 5px;
font-weight: 400;
display: inline-block;
}

.c-om-description__title {
font-size: 1.8rem;
line-height: 2.2rem;
margin-bottom: 2rem;
font-weight:bold;
}
.btn {
display: inline-block;
height: 51px;
border-radius: 0;
font-weight: 400;
line-height: 48px;
padding: 0 28px;
border: 1px solid #039bef;
background-color: #039bef;
color: white;
-webkit-transition: all 300ms ease;
transition: all 300ms ease;
}