.c-buttons {
	position: relative;
	padding: 20px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	@media (max-width: $sw-xx-max){
		flex-direction: column;
		align-items: center;
	}
	.button{
		@media (max-width: $sw-xx-max){
			width: 20rem;
		}
		&:not(:last-child){
			margin-right: 3rem;
			@media (max-width: $sw-xx-max){
				margin-right: 0;
				margin-bottom: 10px;
			}
		}
	}
	&__bordered{
		&:after{
			position: absolute;
			content: '';
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			min-width: 67rem;
			height: 2px;
			background-color: rgba(#979797, 0.33);
			@media (max-width: $sw-xs-max){
				min-width: 90%;
			}
		}
	}
}