.c-information {
	background-color: $dark-bg;
	color: #fff;
	width: 100%;
	font-size: 15px;
	position: relative;
	z-index: 2;
	@media (max-width: $sw-xx-max) {
		background-color: #fff;
		color: #333;
		box-shadow: 0 0 5px #333;
		margin: 1rem 1.5rem 0;
		border-radius: 2px;
		order: 1;
		width: auto;
		.container{
			padding: 0 1rem;
		}
	}

	&__wrap{
		display: flex;
		justify-content: space-between;
		padding: 2.4rem 2rem;
		@media (max-width: $sw-sm-max) {
			flex-wrap: wrap;
			padding: 15px 0;
		}
	}

	&__descr{
		width: 30%;
		padding-right: 50px;
		@media (max-width: $sw-sm-max) {
			width: 50%
		}
		@media (max-width: $sw-xx-max) {
			width: auto;
			padding-right: 0;
		}
		&__title{
			margin-bottom: 2rem;
			@media (max-width: $sw-xx-max) {
				margin-bottom: 8px;
			}
		}
		&__text{
			line-height: 1.5em;
		}
	}

	&__item{
		width: 20%;
		@media (max-width: $sw-sm-max) {
			width: 50%;
		}
		@media (max-width: $sw-xx-max) {
			width: 100%;
		}
	}
}

.info-item{
	@media (max-width: $sw-xx-max) {
		margin-top: 1rem;
		&:nth-child(1){
			order: 3;
		}
		&:nth-child(2){
			display: none;
		}
		&:nth-child(3){
			order: 2;
		}
	}

	&__title{
		position: relative;
		text-transform: uppercase;
		padding-bottom: 1.5rem;
		display: block;
		    font-size: 12px;
		@media (max-width: $sw-xx-max) {
			text-transform: capitalize;
			padding-bottom: 0.5rem;
		}
		&:after{
			position: absolute;
			content: '';
			bottom: 0;
			left: 0;
			width: 5rem;
			height: 3px;
			background-color: #fff;
		}
	}

	&__text{
		display: block;
		color: #fff;
		text-decoration: none;
		margin: 2rem 0;
		    font-size: 12px;
		&:hover{
			color: #fff;
		}
		@media (max-width: $sw-xx-max) {
			color: #333;
			margin: 0.5rem 0;
			&:hover{
				color: #333;
			}
		}
	}
}