.c-header{
  box-shadow: 0 2px 4px 0 rgba(147,147,147,0.5);
  z-index: 101;
  position: relative;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__contact {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h2, a{
          font-size: 1.8rem;
        font-weight: 400;
        line-height: 1.2;
        color: black;
    }
    p{
          padding: 0;
    margin: 0;
    }
    @media(max-width: $sw-xs-max) {
      flex-grow: 1;
      flex-direction: row;
      padding-left: 20px;
    }
    @media(max-width: $sw-xx-max) {
      padding-left: 10px;
    }
    span {
      &:first-child {
        @media(max-width: $sw-xs-max) {
          display: none;
        }
      }
    }
  }
  & .sub-menu {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-20px);
    transition: all .3s ease;

    border-radius: 0 0 2px 2px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,0.5);
    min-width: 14rem;
    padding: 10px 0 25px;
    z-index: 2;

    position: absolute;
    top: calc(100% + .3rem);
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    li {
      display: block;
      width: 100%;
    }
    a {
      display: block;
      width: 100%;
      padding: 5px 10px 5px 18px;
      background-color: white;
      transition: all .3s ease;
      &:hover {
        background-color: #EBEBEB;
      }
    }
  }
  &__logo {
    height: 45.75px;
    width: 123px;
    position: relative;
    @media(max-width: $sw-xx-max) {
      height: 40px;
      width: 100px;
    }
    img {
      display: block;
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
  nav {
    flex-grow: 1;
    max-width: 55%;
    @media (max-width: $sw-sm-max) {
      max-width: 65%;
    }
    @media(max-width: $sw-xs-max) {
      position: fixed;
      margin-left: 0;
      width: 280px;
      left: 0;
      top: 50px;
      bottom: 0;
      background-color: white;
      overflow: hidden;
      overflow-y: auto;
      z-index: 100;
      height: auto;
      padding: 15px 0 0;
      transform: translateX(-110%);
      transition: transform 0.3s ease;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      .menu-open & {
        transform: translateX(0);
      }
    }
    @media(max-width: $sw-xx-max) {
      width: 100%;
      max-width: 100%;
    }
    & > ul {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 100%;
      width: 100%;

      @media(max-width: $sw-xs-max) {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        .menu-open & {
          transform: translateX(0);
        }
      }
      & > li {
        position: relative;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 30px 20px;
        border-bottom: .3rem solid transparent;
        @media (max-width: $sw-sm-max) {
          padding: 30px 15px;
        }
        @media(max-width: $sw-xs-max) {
          padding: 10px 30px;
          height: auto;
          width: 100%;
        }
        &:hover {
          .sub-menu {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
            @media(max-width: $sw-xs-max) {
              display: none;
            }
          }
        }
        & > a {
          text-transform: uppercase;
        }
      }
      .c-header__current-link, .current_page_item, .current_menu_item, .current-page-ancestor {
        border-bottom: .3rem solid #FF0055;
        @media(max-width: $sw-xs-max) {
          border-bottom: .3rem solid transparent;
        }
      }
      a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #333333;
      }
    }
  }
  span {
    color: #333333;
    font-size: 1.5rem;
    line-height: 1.4em;
  }
  &--over {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: none;
    background-color: transparent;
    @media(max-width: $sw-xs-max) {
      position: relative;
      background-color: white;
    }
    .menu > li > a > span {
      color: white;
      @media(max-width: $sw-xs-max) {
        color: #333333;
      }
    }
    //& + main {
    //  padding-top: 84px;
    //}
  }

  &__burger-details {
    display: none;

    width: 100%;
    background-color: #E7E7E7;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 30px 35px;
    @media(max-width: $sw-xs-max) {
      display: flex;
    }
    span {
      display: block;
      margin-bottom: 5px;
    }
  }

  &__burger-menu {
    display: none;
    @media ( max-width: $sw-xs-max) {
      width: 5rem;
      height: 5rem;
      display: block;
      position: relative;
      flex-shrink: 0;
      cursor: pointer;
      z-index: 10;
      span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: block;
        width: 3.2rem;
        height: 0.2rem;
        background: #1F2531;
        transition: none;
        &:before{
          content: '';
          position: absolute;
          width: 100%;
          height: 0.2rem;
          background: #1F2531;
          top: -1rem;
          left: 0;
          transition: all .3s ease;
        }
        &:after{
          content: '';
          position: absolute;
          width: 100%;
          height: 0.2rem;
          background: #1F2531;
          top: 1rem;
          left: 0;
          transition: all .3s ease;
        }
      }

      &-cross {
        span {
          display: block;
          width: 3.2rem;
          height: 0;
          background: #1F2531;
          &:before{
            content: '';
            position: absolute;
            width: 100%;
            height: 0.2rem;
            background: #1F2531;
            top: -0.8rem;
            left: 0;
            transform-origin: 75%;
            transform: rotate(-45deg);
          }
          &:after{
            content: '';
            position: absolute;
            width: 100%;
            height: 0.2rem;
            background: #1F2531;
            top: 0.4rem;
            left: 0;
            transform-origin: 75%;
            transform: rotate(45deg);
          }
        }
        &-active {
          span {
            &:after {
              background-color: #1F2531;
            }
            &:before {
              background-color: #1F2531;
            }
          }
        }
      }
    }
  }
}


.close-menu-field {
  position: fixed;
  top: 50px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 50;
  background-color: rgba(18, 20, 21, 0.7);
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s ease;

  .menu-open & {
    opacity: 1;
    visibility: visible;
  }
}

.menu-open {
  overflow: hidden;
  height: 100vh;
}

.c-header--over .c-header__contact h2, .c-header--over .c-header__contact a, .c-header--over .c-header__contact{
  color:#fff;
}

