.c-om-description {
	position: relative;
	padding: 4.4rem 0 2.8rem;
	@media (max-width: $sw-xs-max){
		padding: 3.5rem 0;
	}
	@media (max-width: $sw-xx-max){
		padding: 3rem 0;
	}

	&__title{
		font-size: 1.8rem;
		line-height: 2.2rem;
		margin-bottom: 2rem;
	}

	&__text{
		font-size: 1.5rem;
		line-height: 2.2rem;
		margin-bottom: 4rem;
	}
}